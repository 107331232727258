import { useGlobalContentStore } from '~/store/globalContent';

export const useLocalizedCurrency = (price: number, withoutFractions?: boolean): string => {
  const options = {} as Intl.NumberFormatOptions;
  const globalContent = useGlobalContentStore();
  let addedOutput = '';
  if (withoutFractions) {
    options.maximumFractionDigits = 0;
  }

  // NOK is wrong, so if NOK, we manually format output
  if (globalContent.getCurrency !== 'NOK') {
    options.style = 'currency';
    options.currency = globalContent.getCurrency;
    options.currencyDisplay = 'narrowSymbol';
  } else {
    addedOutput = ' kr';
  }

  const data = new Intl.NumberFormat(
    globalContent.getLocale,
    options).format(price);

  return data + addedOutput;
};
